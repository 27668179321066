import SEO from '../components/SEO';
import React, { useState, useContext, useEffect } from 'react';
import { authCheck, hideLoader } from '../utils/utils';
import { GlobalDispatchData } from '../storage/GlobalDataProvider';
import { GlobalStateData } from '../storage/GlobalDataProvider';
import { navigate } from '@reach/router';
import { Card, CardBody } from '@paljs/ui/Card';
import { ButtonLink } from '@paljs/ui/Button';

const Home = () => {
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    hideLoader();
  }, []);
  return (
    <div>
      <SEO title="Home" />
      <Card>
        <CardBody>
          <h1>Welcome to UBL</h1>
          <small></small>
        </CardBody>
      </Card>
    </div>
  );
};
export default Home;
